<template>
  <!-- 扩展：权限测试组件 -->
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item
          ><i class="el-icon-lx-emojifill"></i>自定义表单</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <div class="container">
        <h1>使用方法</h1>
        <p>直接通过设置类名为el-icon-lx-iconname来即可使用</p>
        <span id="one">
            <i class="el-icon-lx-redpacket_fill"></i>
            <p v-text="hsj"></p>
        </span>
        <span id="one">
            <i class="el-icon-lx-weibo"></i>
            <p v-text="lhj"></p>
        </span>
        <span id="one">
            <i class="el-icon-lx-emojifill"></i>
            <p v-text="ll"></p>
        </span>
        <h1>图标</h1>

        <div id="four" ref="hhh" >
            <div id="three" v-for="(item,index) in xpy" :key="index">
            <i class="el-icon-lx-weibo" id="two"></i>
            <p>{{item.name}}</p>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
window.addEventListener('load', function () {
  window.addEventListener('resize', function () {
    this.$refs.hhh.style.width = window.innerWidth
  })
})

export default ({
  name: 'Icon',
  data () {
    return {
      hsj: '<i class="el-icon-lx-redpacket_fill"></i>',
      lhj: '<i class="el-icon-lx-weibo"></i>',
      ll: '<i class="el-icon-lx-emojifill"></i>',
      xpy: [{ name: 'weibo', icon: '' }, { name: 'weibo' }, { name: 'weibo' }, { name: 'weibo' }, { name: 'weibo' }, { name: 'weibo' }, { name: 'weibo' }, { name: 'weibo' }, { name: 'weibo' }, { name: 'weibo' }, { name: 'weibo' }, { name: 'weibo' }, { name: 'weibo' }]
    }
  }
})
</script>

<style scoped>
    h1 {
        margin: 30px 30px;
    }
    p {
        line-height: 30px;
        margin-bottom: 10px;
        text-indent: 2em;
    }
    span{
        height:30px;
        margin: 0 30px;
    }
    span i {
        margin: 6px 0;
    }
    #one{
        display:flex;
        justify-content:flex-start;
    }
    #one p{
        margin-left:-35px ;
    }
    #three{
        width: 120px;
        height: 150px;
    }
    #three i {
        font-size: 100px;
        margin: 0 10px 20px 10px;
    }
    #three p{
        text-align: center;
        margin-left: -30px;
    }
    #four{
        width: 100%;
        display: flex;
        justify-items: auto;
        flex-wrap: wrap;
    }
</style>
